import { useSelector } from 'src/domains/root/store';
import { activeWorkspaceSelector } from 'src/domains/root/features/users/slice';

export default function useActiveWorkspace() {
  const workspace = useSelector(activeWorkspaceSelector);

  if (!workspace) {
    throw new Error('Active workspace is missing.');
  }

  return workspace;
}
