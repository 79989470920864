import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { downloadFile } from 'src/utils/file';
import { API } from 'src/apis/api';
import useActiveWorkspace from '../workspaces/useActiveWorkspace';
import { useGetWorkspacesReportsDownloadUrl } from './useGetWorkspacesReportsDownloadUrl';
import ReportsDownloadDialog from './ReportsDownloadDialog';

interface ReportsDownloadDialogContainerProps {
  report: API.Report;
  open: boolean;
  onClose: () => void;
}

export default function ReportsDownloadDialogContainer(
  props: ReportsDownloadDialogContainerProps,
) {
  const { report, open, onClose } = props;

  const workspace = useActiveWorkspace();
  const reportsDownloadUrl = useGetWorkspacesReportsDownloadUrl();

  const isCreating =
    !reportsDownloadUrl.data?.downloadUrl &&
    reportsDownloadUrl.data?.status === 'creating';

  const handleDownload = () => {
    downloadReport(workspace, report, 'csv', reportsDownloadUrl);
  };

  useEffect(() => {
    return () => {
      reportsDownloadUrl.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (
    reportsDownloadUrl.status === 'hasError' &&
    reportsDownloadUrl.errorCode !== 'NOT_FOUND_REPORT' &&
    reportsDownloadUrl.errorCode !== 'unknown_error'
  ) {
    return (
      <RedirectionForGetWorkspacesReportsDownloadUrl
        errorCode={reportsDownloadUrl.errorCode}
      />
    );
  }

  return (
    <>
      {open && (
        <ReportsDownloadDialog
          open={open}
          isCreating={isCreating}
          downloadStatus={reportsDownloadUrl.status}
          downloadErrorCode={reportsDownloadUrl.errorCode}
          onDownload={handleDownload}
          onClose={onClose}
        />
      )}
    </>
  );
}

const downloadReport = async (
  workspace: API.Workspace,
  report: API.Report,
  type: API.ReportDownloadType,
  reportsDownloadUrl: ReturnType<typeof useGetWorkspacesReportsDownloadUrl>,
) => {
  const status = reportsDownloadUrl.status;

  if (status === 'loading') {
    return;
  }

  const res = await reportsDownloadUrl.mutate(workspace, report, type);

  if (res.status !== 'creating' && res.downloadUrl) {
    downloadFile({ fileURL: res.downloadUrl });
  }
};

function RedirectionForGetWorkspacesReportsDownloadUrl({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'permission_denied':
    case 'NOT_JOINED_WORKSPACE':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}
