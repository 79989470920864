import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import * as API from 'src/apis';
import { handleError } from './error';

type Props = {
  workspaceId: string;
  reportId: string;
  token: string;
};

export async function getWorkspaceReport({
  workspaceId,
  reportId,
  token,
}: Props): Promise<{ report: API.Report }> {
  return axios
    .get(`${API_ENDPOINT}/workspaces/${workspaceId}/reports/${reportId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
