import React from 'react';
import {
  SnackbarProvider,
  SnackbarProviderProps,
  MaterialDesignContent,
} from 'notistack';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';
import { Error } from '@mui/icons-material';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) =>
  theme.unstable_sx({
    '&.notistack-MuiContent': {
      backgroundColor: 'white',
      color: 'black',
    },
    '& #notistack-snackbar': {
      width: { sm: 300 },
    },

    '&.notistack-MuiContent-success': {
      borderLeft: 'solid',
      borderLeftColor: '#43a047',
      '& #notistack-snackbar > svg': {
        color: '#43a047',
      },
    },
    '&.notistack-MuiContent-error': {
      borderLeft: 'solid',
      borderLeftColor: '#d32f2f',
      '& #notistack-snackbar > svg': {
        color: '#d32f2f',
      },
    },
    '&.notistack-MuiContent-warning': {
      borderLeft: 'solid',
      borderLeftColor: '#ff9800',
      '& #notistack-snackbar > svg': {
        color: '#ff9800',
      },
    },
    '&.notistack-MuiContent-info': {
      borderLeft: 'solid',
      borderLeftColor: '#2196f3',
      '& #notistack-snackbar > svg': {
        color: '#2196f3',
      },
    },
  }),
);

const StyledErrorV2MaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) =>
    theme.unstable_sx({
      '&.notistack-MuiContent': {
        backgroundColor: 'white',
        color: '#141414',

        display: 'flex',

        width: { xs: 'unset', sm: 468 },
        height: { xs: 'unset', sm: 84 },

        padding: '18px 16px',

        border: 'solid 2px #E80029',
        borderLeft: 'solid 10px #E80029',

        '& svg': {
          color: '#E80029',
        },

        '& > div:nth-child(2)': {
          paddingLeft: 0,
        },
      },
      '& #notistack-snackbar': {
        padding: 0,
        flex: 1,

        '& div': {
          marginLeft: 1.25,
          fontSize: 16,
          lineHeight: 1.5,
        },
      },
    }),
);

const StyledSnackbarProvider: React.FC<SnackbarProviderProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <SnackbarProvider
      iconVariant={{
        success: <CheckCircleOutlineIcon />,
        error: <ErrorOutlineIcon />,
        errorV2: <Error />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        errorV2: StyledErrorV2MaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
      {...rest}
    >
      {children}
    </SnackbarProvider>
  );
};

export default StyledSnackbarProvider;
