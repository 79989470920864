import React from 'react';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';
import CloseIconButton from '../../snackbar/actions/CloseIconButton';

type Props = {
  backgroundColor?: string;
  closeIconButtonColor?: string;
  footer?: React.ReactNode;
  onClose?: () => void;
  children: React.ReactNode;
};
export default function AlertNotification({
  backgroundColor = '#FFBC59',
  closeIconButtonColor = '#000000',
  footer,
  onClose,
  children,
}: Props) {
  const close = () => {
    onClose?.();
  };

  return (
    <Alert
      icon={false}
      sx={{
        px: {
          xs: 2,
          sm: 3,
        },
        py: 0.5,
        backgroundColor,
        fontSize: {
          xs: 14,
          sm: 16,
        },
        fontWeight: 700,
        color: '#000',
        alignItems: 'center',
        borderRadius: 0,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box pr={4.5}>{children}</Box>
        {onClose && (
          <CloseIconButton
            onClick={close}
            sx={{
              color: closeIconButtonColor,
              position: 'absolute',
              right: 16,
              svg: {
                width: 30,
                height: 30,
              },
            }}
          />
        )}
      </Box>
      {footer}
    </Alert>
  );
}
