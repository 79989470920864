import { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import * as yup from 'yup';
import {
  BreadcrumbTitleV3,
  BreadcrumbsV3,
} from 'src/domains/root/commons/breadcrumbs';
import { toYmdSlashHm } from 'src/utils/time';
import { HeadTitle, InputField, Text } from 'src/shared/ui';
import { Menu, MenuItem } from 'src/domains/root/commons/Menu';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import PrimaryButton from 'src/domains/root/commons/buttons/Primary';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';

import * as API from 'src/apis';
import { MenuRounded } from '@mui/icons-material';
import { usePutWorkspacesReport } from './usePutWorkspacesReport';

const validationSchema = yup.object().shape({
  title: yup.string().required().min(1).max(50),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

type Menu = 'updateTitle' | null;

export default function ReportsDetail({
  report,
  updateReport,
}: {
  report?: API.Report;
  updateReport: ReturnType<typeof usePutWorkspacesReport>;
}) {
  const [selectedMenu, setSelectedMenu] = useState<Menu>(null);

  const initialValues = {
    title: report?.title,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!report || !values.title) {
        return;
      }

      const res = await updateReport.mutate({ ...report, title: values.title });

      formik.setValues({
        ...values,
        title: res.title,
      });
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const handleSelectMenu = (menu: Menu) => {
    setSelectedMenu(menu);
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={3.5}>
      <Breadcrumb />

      <FormikProvider value={formik}>
        <ReportsDetailPaper>
          <HeadMenu
            disabled={!report || selectedMenu === 'updateTitle'}
            handleSelectMenu={handleSelectMenu}
          />
          <ReportOverview
            report={report}
            selectedMenu={selectedMenu}
            handleSelectMenu={handleSelectMenu}
          />
        </ReportsDetailPaper>
      </FormikProvider>
    </Box>
  );
}

function Breadcrumb() {
  const intl = useIntl();

  return (
    <Box pl={{ xs: 2, sm: 0 }}>
      <BreadcrumbsV3>
        <Link to="/reports">
          <BreadcrumbTitleV3 color="primary">
            {intl.formatMessage({
              id: 'pages.Reports.title',
            })}
          </BreadcrumbTitleV3>
        </Link>
        <BreadcrumbTitleV3>
          {intl.formatMessage({
            id: 'pages.Reports.Detail.title',
          })}
        </BreadcrumbTitleV3>
      </BreadcrumbsV3>
    </Box>
  );
}

function ReportsDetailPaper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={3}
      bgcolor="white"
      p={3}
      border={{ xs: 'none', sm: '1px solid #828282' }}
      borderRadius={1}
    >
      {children}
    </Box>
  );
}

function HeadMenu({
  disabled,
  handleSelectMenu,
}: {
  disabled?: boolean;
  handleSelectMenu: (menu: Menu) => void;
}) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUpdateTitle = () => {
    handleSelectMenu('updateTitle');
    setAnchorEl(null);
  };

  return (
    <Box position="relative">
      <HeadTitle>
        {intl.formatMessage({
          id: 'pages.Reports.Detail.headTitle',
        })}
      </HeadTitle>
      <IconButton
        disabled={disabled}
        aria-label="menu"
        onClick={handleOpenMenu}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          mt: -15 / 8,
          mr: -1.5,
          opacity: disabled ? '40%' : 'unset',
        }}
      >
        <MenuRounded sx={{ color: 'primary.main', fontSize: 26.67 }} />
      </IconButton>

      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleCloseMenu}>
        <MenuItem disabled={disabled} onClick={handleUpdateTitle}>
          {intl.formatMessage({
            id: 'pages.Reports.Detail.menu.updateTitle',
          })}
        </MenuItem>
      </Menu>
    </Box>
  );
}

function ReportOverview({
  report,
  selectedMenu,
  handleSelectMenu,
}: {
  report?: API.Report;
  selectedMenu?: Menu;
  handleSelectMenu: (menu: Menu) => void;
}) {
  const intl = useIntl();

  const formik = useFormikContext<ValidationSchema>();

  const handleSubmit = async () => {
    await formik.submitForm();

    handleSelectMenu(null);
  };

  const handleClickBackButton = () => {
    handleSelectMenu(null);
    formik.setValues({
      title: report?.title ?? '',
    });
  };

  if (!report) {
    return (
      <Text textAlign="center">
        {intl.formatMessage({
          id: 'pages.Reports.Detail.overview.noData',
        })}
      </Text>
    );
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      {selectedMenu === 'updateTitle' ? (
        <InputField
          label={intl.formatMessage({
            id: 'pages.Reports.Detail.updateTitle.label',
          })}
          name="title"
          value={formik.values.title}
          disabled={formik.isSubmitting}
          error={Boolean(formik.errors.title)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={intl.formatMessage({
            id: 'pages.Reports.Detail.updateTitle.helperText',
          })}
        />
      ) : (
        <Text variant="Title_A">{report.title}</Text>
      )}
      <Box
        display="flex"
        columnGap={3.5}
        rowGap={1}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box display="flex" columnGap={1} flex="none">
          <Text variant="Basic_A">
            {intl.formatMessage({
              id: 'pages.Reports.Detail.overview.targetPeriod',
            })}
          </Text>
          <Text>
            {toYmdSlashHm(DateTime.fromMillis(report.beginAt))}〜
            {toYmdSlashHm(DateTime.fromMillis(report.endAt))}
          </Text>
        </Box>
        <Box display="flex" columnGap={1}>
          <Text variant="Basic_A" flex="none">
            {intl.formatMessage({
              id: 'pages.Reports.Detail.overview.gatewayName',
            })}
          </Text>
          <Text flex="0 1 auto">{report.gatewayName}</Text>
        </Box>
      </Box>
      {selectedMenu === 'updateTitle' && (
        <Box display="flex" columnGap={2} pt={5} justifyContent="flex-end">
          <NegativeButton onClick={handleClickBackButton}>
            {intl.formatMessage({
              id: 'pages.Reports.Detail.overview.backButton',
            })}
          </NegativeButton>
          <PrimaryButton
            disabled={!formik.isValid || formik.isSubmitting}
            onClick={handleSubmit}
          >
            {intl.formatMessage({
              id: 'pages.Reports.Detail.overview.updateButton',
            })}
          </PrimaryButton>
        </Box>
      )}
      <LoadingOverlay open={formik.isSubmitting} />
    </Box>
  );
}
