import * as API from 'src/apis';

/**
 * @tanstack/react-queryのqueryKeyを生成する。
 * keyの名前空間が重複するとinvalidate時に不具合が発生するため、この実装が推奨されている。
 *
 * @see https://tanstack.com/query/v4/docs/react/community/lukemorales-query-key-factory
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 *
 * # @lukemorales/query-key-factory について
 * 今後このファイルでの管理が煩雑になるのであれば、上記サイトでも紹介されている@lukemorales/query-key-factoryを使うこと。
 * 現状、十分シンプルで管理が容易なため使用していない。
 */
export const Keys = {
  sensorData: {
    suPartial: (
      sensorUnitId: string | undefined,
      begin: number,
      end: number,
    ) => {
      return ['sensorData', 'SU', 'partial', sensorUnitId, begin, end];
    },
    su12w: (sensorUnitId: string | undefined) => {
      return ['sensorData', 'SU', '12w', sensorUnitId];
    },
    suUrl: (sensorUnitId: string | undefined) => {
      return ['sensorData', 'SU', 'url', sensorUnitId];
    },
    suAlertCount: (sensorUnitId: string | undefined) => {
      return ['sensorData', 'SU', 'alertCount', sensorUnitId];
    },
    suFull: (sensorUnitId: string | undefined) => {
      return ['sensorData', 'SU', 'full', sensorUnitId];
    },
    gw12w: (gatewayId: string) => {
      return ['sensorData', 'GW', '12w', gatewayId];
    },
    gwUrl: (gatewayId: string) => {
      return ['sensorData', 'GW', 'url', gatewayId];
    },
    gwFull: (gatewayId: string) => {
      return ['sensorData', 'GW', 'full', gatewayId];
    },
    weatherHistories: (
      areaId: string | undefined,
      begin: number,
      end: number,
    ) => {
      return ['sensorData', 'weatherHistories', areaId, begin, end];
    },
    beginAndEndTimestamp: (gatewayId: string | undefined) => {
      return ['sensorData', 'beginAndEndTimestamp', gatewayId];
    },
  },
  gateway: {
    memos: (gatewayId: string) => {
      return ['gateways', gatewayId, 'memos'];
    },
    memo: (gatewayId: string, memoId: string) => {
      return ['gateways', gatewayId, 'memos', memoId];
    },
  },
  payments: {
    invoices: (
      workspaceId: string,
      status: API.WorkspaceGatewayPaymentInvoiceStatus,
    ) => {
      return ['payments', 'invoices', workspaceId, status];
    },
  },
  reports: {
    item: (reportId: string) => {
      return ['reports', reportId];
    },
    lists: (workspaceId: string) => {
      return ['reports', 'list', workspaceId];
    },
    gateways: {
      sensorDataBeginEndTimestamp: (workspaceId: string) => {
        return ['reports', 'gateways', workspaceId];
      },
    },
  },
};
