import { useAuth0 } from '@auth0/auth0-react';
import { MutationStatus, useMutation } from '@tanstack/react-query';
import * as API from 'src/apis';

type ErrorCode =
  | 'bad_request'
  | 'permission_denied'
  | 'NOT_JOINED_WORKSPACE'
  | 'not_found'
  | 'NOT_FOUND_REPORT'
  | 'emergency_maintenance'
  | 'unknown_error';

const mapErrorCode = (error: API.Error | null): ErrorCode => {
  if (!error) {
    return 'unknown_error';
  }

  const errorCodeMap: Record<string, ErrorCode> = {
    bad_request: 'bad_request',
    permission_denied: 'permission_denied',
    NOT_JOINED_WORKSPACE: 'NOT_JOINED_WORKSPACE',
    not_found: 'not_found',
    NOT_FOUND_REPORT: 'NOT_FOUND_REPORT',
    emergency_maintenance: 'emergency_maintenance',
    unknown_error: 'unknown_error',
  };

  return API.getErrorCode(errorCodeMap, error);
};

type Status = 'idle' | 'loading' | 'succeeded' | 'hasError';

const statusMap: Record<MutationStatus, Status> = {
  idle: 'idle',
  pending: 'loading',
  success: 'succeeded',
  error: 'hasError',
};

type Mutate = (
  report: API.Report,
) => ReturnType<typeof API.putWorkspacesReport>;

type Result = {
  status: Status;
  data?: { title: API.Report['title'] };
  errorCode: ErrorCode;
  mutate: Mutate;
};

export function usePutWorkspacesReport(
  workspace: API.Workspace,
  props: {
    onSuccess: (
      data: Awaited<ReturnType<typeof API.putWorkspacesReport>>,
    ) => void;
    onError: (error: API.Error) => unknown;
  },
): Result {
  const { getAccessTokenSilently } = useAuth0();

  const workspaceId = workspace.workspaceId;

  const result = useMutation<
    Awaited<ReturnType<typeof API.putWorkspacesReport>>,
    API.Error,
    API.Report
  >({
    mutationFn: async ({ reportId, title }) => {
      const token = await getAccessTokenSilently();
      const response = await API.putWorkspacesReport({
        workspaceId,
        reportId,
        title,
        token,
      });

      return response;
    },
    onSuccess: props.onSuccess,
    onError: props.onError,
  });

  const mutate = async (report: API.Report) => {
    return result.mutateAsync(report);
  };

  return {
    status: statusMap[result.status],
    mutate,
    data: result.data,
    errorCode: mapErrorCode(result.error),
  };
}
