import { CalendarTodaySharp } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { InputField } from 'src/shared/ui/InputField';
import { fromUnixMs, toYmdSlash } from 'src/utils/time';

export interface Period {
  beginAt: number;
  endAt: number;
}

interface PeriodInputProps {
  disabled?: boolean;
  label: string;
  placeholder?: string;
  helperText?: string;
  period: Period;
  initialValue?: string;
  isDirty: boolean;
  toggleDialog: () => void;
}

export default function PeriodInput(props: PeriodInputProps) {
  const {
    disabled = false,
    label,
    placeholder,
    helperText,
    period,
    initialValue,
    isDirty,
    toggleDialog,
  } = props;

  const beginDate = fromUnixMs(period.beginAt);
  const endDate = fromUnixMs(period.endAt);

  const isEndDateToday = endDate.hasSame(DateTime.local(), 'day');
  const sameDay = beginDate.hasSame(endDate, 'day');

  const beginDateYmd = toYmdSlash(beginDate);
  const endDateYmd = toYmdSlash(endDate);

  const dateRange = sameDay ? beginDateYmd : `${beginDateYmd}〜${endDateYmd}`;

  const handleDialogOpen = () => {
    if (disabled) {
      return;
    }

    toggleDialog();
  };

  return (
    <InputField
      disabled={disabled}
      fullWidth
      variant="outlined"
      label={label}
      placeholder={placeholder}
      value={isDirty ? dateRange : initialValue}
      helperText={helperText && (isDirty && isEndDateToday ? helperText : ' ')}
      sx={{
        background: disabled ? 'transparent' : 'white',

        fieldset: {
          border: '1px solid #828282',

          '& > legend': {
            fontSize: 12,
          },
        },

        '& .MuiInputBase-root': {
          height: 45,
          cursor: disabled ? 'auto' : 'pointer',
        },
      }}
      InputLabelProps={{
        shrink: true,
        sx: {
          color: '#141414',
        },
      }}
      InputProps={{
        readOnly: true,
        name: 'period',
        endAdornment: (
          <CalendarTodaySharp
            sx={{
              fontSize: 20,
              color: 'primary.main',
              opacity: disabled ? '40%' : '100%',
            }}
          />
        ),
        onClick: handleDialogOpen,
        sx: { input: { cursor: disabled ? 'auto' : 'pointer' } },
      }}
    />
  );
}
