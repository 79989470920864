import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LOCAL_STORAGE_KEY } from 'src/constants';
import { awsRum } from 'src/utils/rum';
import { DateTime } from 'luxon';
import { meResultSelector } from '../../../features/users/slice';
import { getBannerProps } from './bannerService';

export const useContractExpiredAlert = () => {
  const [open, setOpen] = useState<boolean>(false);
  const usersMeData = useSelector(meResultSelector);

  const nearestContractExpirationDay =
    usersMeData.data?.nearestContractExpirationDay;
  const bannerProps =
    typeof nearestContractExpirationDay === 'number'
      ? getBannerProps(nearestContractExpirationDay)
      : null;

  const getLastDisplay = () => {
    return localStorage.getItem(
      LOCAL_STORAGE_KEY.HAS_LATEST_WORKSPACE_NEAR_CONTRACT_EXPIRATION,
    );
  };

  const setLastDisplay = () => {
    try {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.HAS_LATEST_WORKSPACE_NEAR_CONTRACT_EXPIRATION,
        Date.now().toString(),
      );
    } catch (e) {
      awsRum().then((rum) => rum.recordError(e));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLastDisplay();
  };

  useEffect(() => {
    const shouldShow = shouldShowBanner(getLastDisplay());
    const hasExpiration =
      usersMeData.data?.hasLatestWorkspaceNearContractExpiration ?? false;

    setOpen(shouldShow && hasExpiration);
  }, [usersMeData]);

  return {
    open,
    handleClose,
    bannerProps,
  };
};

const shouldShowBanner = (lastDisplayTimestamp: string | null): boolean => {
  if (!lastDisplayTimestamp) {
    return true;
  }

  const lastDisplayTime = DateTime.fromMillis(Number(lastDisplayTimestamp), {
    zone: 'Asia/Tokyo',
  });
  const startOfNextDay = lastDisplayTime.plus({ days: 1 }).startOf('day');
  const now = DateTime.now().setZone('Asia/Tokyo');

  return now >= startOfNextDay;
};
