import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import * as API from 'src/apis';
import { handleError } from './error';

type Props = {
  workspaceId: string;
  reportId: string;
  type: API.ReportDownloadType;
  token: string;
};

export async function getWorkspacesReportsDownloadUrl({
  workspaceId,
  reportId,
  type,
  token,
}: Props): Promise<API.WorkspacesReportsDownloadUrl> {
  return axios
    .get(
      `${API_ENDPOINT}/workspaces/${workspaceId}/reports/${reportId}/download-url?type=${type}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
