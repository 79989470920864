import useActiveWorkspace from 'src/domains/root/features/workspaces/useActiveWorkspace';
import { useContractExpiredAlert } from './useContractExpiredAlert';
import { ContractExpiredAlertBanner } from './ContractExpiredAlertBanner';

export function ContractExpiredAlertContainer() {
  const { open, handleClose, bannerProps } = useContractExpiredAlert();
  const workspace = useActiveWorkspace();

  const isAdmin = workspace.role === 'admin';

  return (
    <ContractExpiredAlertBanner
      open={open}
      onClose={handleClose}
      isAdmin={isAdmin}
      bannerProps={bannerProps}
    />
  );
}
