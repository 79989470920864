import { Navigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import useActiveWorkspace from 'src/domains/root/features/workspaces/useActiveWorkspace';
import { Box, CircularProgress } from '@mui/material';
import UnrecoverableError from 'src/domains/root/pages/errors/UnrecoverableError';
import useCabiotSnackbar from 'src/domains/root/commons/snackbar';
import * as API from 'src/apis';
import ReportsDetail from './ReportsDetail';
import { useGetWorkspacesReport } from './useGetWorkspacesReport';
import { usePutWorkspacesReport } from './usePutWorkspacesReport';

export default function ReportsDetailContainer() {
  const workspace = useActiveWorkspace();
  const { reportId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useCabiotSnackbar();

  if (!reportId) {
    throw new Error('The reportId is missing in path params.');
  }

  const getReport = useGetWorkspacesReport(workspace, reportId);
  const putReport = usePutWorkspacesReport(workspace, {
    onSuccess: (report) => {
      queryClient.setQueryData(
        getReport.keys,
        (old: { report: API.Report }) => ({
          report: {
            ...old.report,
            title: report?.title,
          },
        }),
      );

      enqueueSnackbar('pages.Reports.Detail.updateTitle.success', 'success');
    },
    onError: (e) => {
      if (e.code !== 'unknown_error') {
        return;
      }

      enqueueSnackbar(
        'pages.Reports.Detail.PUT_WORKSPACES_REPORT_UNKOWN_ERROR',
        'errorV2',
      );
    },
  });

  if (getReport.status === 'loading') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (
    getReport.status === 'hasError' &&
    getReport.errorCode !== 'NOT_FOUND_REPORT'
  ) {
    return (
      <RedirectionForGetWorkspacesReport errorCode={getReport.errorCode} />
    );
  }

  if (
    putReport.status === 'hasError' &&
    putReport.errorCode !== 'unknown_error'
  ) {
    return (
      <RedirectionForPutWorkspacesReport errorCode={putReport.errorCode} />
    );
  }

  const report = getReport.data;

  return <ReportsDetail report={report} updateReport={putReport} />;
}

function RedirectionForGetWorkspacesReport({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'permission_denied':
    case 'NOT_JOINED_WORKSPACE':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    default:
      return <Navigate to={GetWorkspacesReportUnkownErrorPageRoute} />;
  }
}

function RedirectionForPutWorkspacesReport({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'NOT_FOUND_REPORT':
      return <Navigate to={NotFoundReportErrorPageRoute} />;
    case 'permission_denied':
    case 'NOT_JOINED_WORKSPACE':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    default:
      return <Navigate to={PutWorkspacesReportUnkownErrorPageRoute} />;
  }
}

export function GetWorkspacesReportUnkownErrorPage() {
  return (
    <UnrecoverableError messageId="pages.Reports.Detail.GET_WORKSPACES_REPORT_UNKOWN_ERROR" />
  );
}

export const GetWorkspacesReportUnkownErrorPageRoute =
  '/errors/reports/get-workspaces-report-unkown-error';

export function PutWorkspacesReportUnkownErrorPage() {
  return (
    <UnrecoverableError messageId="pages.Reports.Detail.PUT_WORKSPACES_REPORT_UNKOWN_ERROR" />
  );
}

export const PutWorkspacesReportUnkownErrorPageRoute =
  '/errors/reports/put-workspaces-report-unkown-error';

export function NotFoundReportErrorPage() {
  return (
    <UnrecoverableError messageId="pages.Reports.Detail.NOT_FOUND_REPORT" />
  );
}

export const NotFoundReportErrorPageRoute = '/errors/reports/not-found-report';
