export interface BannerProps {
  baseColor: string;
  backgroundColor: string;
  emphasisText: string;
  emphasisColor: string;
}

interface BannerConfig {
  maxDays: number;
  minDays: number;
  props: BannerProps;
}

const colorThemes = {
  month: {
    baseColor: '#141414',
    backgroundColor: '#FFBC59',
    emphasisColor: '#AD0100',
  },
  week: {
    baseColor: '#FFFFFF',
    backgroundColor: '#DE3427',
    emphasisColor: '#FFF98F',
  },
};

export const bannerConfigs: BannerConfig[] = [
  {
    maxDays: 31,
    minDays: 22,
    props: {
      ...colorThemes.month,
      emphasisText: 'month',
    },
  },
  {
    maxDays: 21,
    minDays: 15,
    props: {
      ...colorThemes.month,
      emphasisText: '3weeks',
    },
  },
  {
    maxDays: 14,
    minDays: 8,
    props: {
      ...colorThemes.month,
      emphasisText: '2weeks',
    },
  },
  {
    maxDays: 7,
    minDays: 4,
    props: {
      ...colorThemes.week,
      emphasisText: '1week',
    },
  },
  {
    minDays: 3,
    maxDays: 3,
    props: {
      ...colorThemes.week,
      emphasisText: '3days',
    },
  },

  {
    minDays: 2,
    maxDays: 2,
    props: {
      ...colorThemes.week,
      emphasisText: '2days',
    },
  },

  {
    minDays: 1,
    maxDays: 1,
    props: {
      ...colorThemes.week,
      emphasisText: '1day',
    },
  },

  {
    minDays: 0,
    maxDays: 0,
    props: {
      ...colorThemes.week,
      emphasisText: 'dDay',
    },
  },
];

export const getBannerProps = (daysLeft: number): BannerProps | null => {
  const config = bannerConfigs.find(
    (config) => daysLeft >= config.minDays && daysLeft <= config.maxDays,
  );

  return config ? config.props : null;
};
