import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import { Link } from 'react-router-dom';
import { Text } from 'src/shared/ui';
import SecondaryButton from '../../buttons/Secondary';
import AlertNotification from './AlertNotification';
import { BannerProps } from './bannerService';

interface ContractExpiredAlertBannerProps {
  open: boolean;
  onClose: () => void;
  isAdmin: boolean;
  bannerProps: BannerProps | null;
}

export function ContractExpiredAlertBanner(
  props: ContractExpiredAlertBannerProps,
) {
  const { open, onClose, isAdmin, bannerProps } = props;

  const isSmallScreen = useSmallScreen();
  const intl = useIntl();

  if (!open || bannerProps === null) {
    return;
  }

  return (
    <AlertNotification
      backgroundColor={bannerProps.backgroundColor}
      closeIconButtonColor={bannerProps.baseColor}
      footer={isSmallScreen && <GoToPageButton isAdmin={isAdmin} />}
      onClose={onClose}
    >
      <Box display="flex" alignItems="center">
        <Box>
          <Text
            variant="Title_A"
            color={bannerProps.emphasisColor}
            component="span"
          >
            {intl.formatMessage({
              id: `globalHeader.alertForContractNearExpiration.period.${bannerProps.emphasisText}`,
            })}
          </Text>
          <Text
            variant="Title_A"
            color={bannerProps.baseColor}
            component="span"
          >
            {intl.formatMessage({
              id:
                bannerProps.emphasisText === 'dDay'
                  ? 'globalHeader.alertForContractNearExpiration.dDaymessage'
                  : 'globalHeader.alertForContractNearExpiration.message',
            })}
          </Text>
        </Box>
        {!isSmallScreen && <GoToPageButton isAdmin={isAdmin} />}
      </Box>
    </AlertNotification>
  );
}

interface GoToPageButtonProps {
  isAdmin: boolean;
}

function GoToPageButton(props: GoToPageButtonProps) {
  const { isAdmin } = props;

  const intl = useIntl();

  return (
    <Box
      display={{ xs: 'flex', sm: 'auto' }}
      justifyContent="center"
      pl={{ xs: 0, sm: 1 }}
      pt={{ xs: 2, sm: 0 }}
      flex="1 0 auto"
    >
      <Link to="/settings/others/contracts">
        <SecondaryButton>
          {intl.formatMessage({
            id: `pages.OtherSetting.contracts.list.${isAdmin ? 'title' : 'normalUserTitle'}`,
          })}
        </SecondaryButton>
      </Link>
    </Box>
  );
}
