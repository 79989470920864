import { Box, CircularProgress, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { HeadTitle } from 'src/shared/ui';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActionsV2,
  DialogActionButton,
  CancelButton,
  DialogTitleAlert,
} from 'src/domains/root/commons/dialog';
import { API } from 'src/apis/api';

interface ReportsDownloadDialogProps {
  open: boolean;
  isCreating: boolean;
  downloadStatus: API.Status;
  downloadErrorCode: string;
  onDownload: () => void;
  onClose: () => void;
}

export default function ReportsDownloadDialog(
  props: ReportsDownloadDialogProps,
) {
  const {
    open,
    isCreating,
    downloadStatus,
    downloadErrorCode,
    onDownload,
    onClose,
  } = props;

  const intl = useIntl();

  const isLoading = downloadStatus === 'loading';

  return (
    <Dialog loggingId="ReportsDownloadDialog" open={open} fullWidth>
      <DialogTitle onClose={onClose}>
        {intl.formatMessage({
          id: 'pages.Reports.downloadDialog.title',
        })}
      </DialogTitle>
      <DialogTitleAlert
        status={isCreating ? 'hasError' : downloadStatus}
        message={{
          success: intl.formatMessage({
            id: 'pages.Reports.reportListTable.csvDownloadSuccess',
          }),
          error: isCreating
            ? intl.formatMessage(
                {
                  id: 'pages.Reports.reportListTable.csvDownloadFailed.content.creating',
                },
                {
                  br: <br />,
                },
              )
            : intl.formatMessage(
                {
                  id: `pages.Reports.reportListTable.csvDownloadFailed.content.${downloadErrorCode}`,
                },
                {
                  br: <br />,
                },
              ),
        }}
      />
      <DialogContent>
        <HeadTitle fontSize={16}>
          {intl.formatMessage({
            id: 'pages.Reports.downloadDialog.headTitle',
          })}
        </HeadTitle>
        <Box pl={2} pt={2}>
          <Box
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Typography>
              {intl.formatMessage({
                id: 'pages.Reports.downloadDialog.csv.label',
              })}
            </Typography>
            <Typography fontSize={12}>
              {intl.formatMessage({
                id: 'pages.Reports.downloadDialog.csv.description',
              })}
            </Typography>
          </Box>
        </Box>
        <Box
          display={isLoading ? 'flex' : 'none'}
          flexDirection="column"
          alignItems="center"
          pt={3}
        >
          <Box pb={1}>
            <CircularProgress size={24} />
          </Box>
          <Typography>
            {intl.formatMessage({
              id: 'pages.Reports.downloadDialog.loading',
            })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActionsV2>
        <CancelButton onClick={onClose} />
        <DialogActionButton onClick={onDownload} disabled={isLoading}>
          {intl.formatMessage({
            id: 'pages.Reports.downloadDialog.actionButton',
          })}
        </DialogActionButton>
      </DialogActionsV2>
    </Dialog>
  );
}
