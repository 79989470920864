import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, QueryStatus, QueryKey } from '@tanstack/react-query';
import { Keys } from 'src/domains/root/react-query-keys-factory';
import * as API from 'src/apis';

type ErrorCode =
  | 'bad_request'
  | 'permission_denied'
  | 'NOT_JOINED_WORKSPACE'
  | 'not_found'
  | 'NOT_FOUND_REPORT'
  | 'emergency_maintenance'
  | 'unknown_error';

const mapErrorCode = (error: API.Error | null): ErrorCode => {
  if (!error) {
    return 'unknown_error';
  }

  const errorCodeMap: Record<string, ErrorCode> = {
    bad_request: 'bad_request',
    permission_denied: 'permission_denied',
    NOT_JOINED_WORKSPACE: 'NOT_JOINED_WORKSPACE',
    not_found: 'not_found',
    NOT_FOUND_REPORT: 'NOT_FOUND_REPORT',
    emergency_maintenance: 'emergency_maintenance',
    unknown_error: 'unknown_error',
  };

  return API.getErrorCode(errorCodeMap, error);
};

type Status = 'loading' | 'succeeded' | 'hasError';

const statusMap: Record<QueryStatus, Status> = {
  pending: 'loading',
  success: 'succeeded',
  error: 'hasError',
};

type Result = {
  status: Status;
  data?: API.Report;
  errorCode: ErrorCode;
  keys: QueryKey;
};

export function useGetWorkspacesReport(
  workspace: API.Workspace,
  reportId: string,
): Result {
  const { getAccessTokenSilently } = useAuth0();

  const workspaceId = workspace.workspaceId;
  const queryKey = Keys.reports.item(reportId);

  const result = useQuery<
    Awaited<ReturnType<typeof API.getWorkspaceReport>>,
    API.Error
  >({
    queryKey,
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      return API.getWorkspaceReport({
        workspaceId,
        reportId,
        token,
      });
    },

    refetchOnWindowFocus: false,
  });

  return {
    status: statusMap[result.status],
    data: result.data?.report,
    errorCode: mapErrorCode(result.error),
    keys: queryKey,
  };
}
