import { API_ENDPOINT } from 'src/constants';
import axios from 'src/utils/api';
import * as API from 'src/apis';
import { handleError } from './error';

type Props = {
  workspaceId: string;
  reportId: string;
  title: string;
  token: string;
};

export async function putWorkspacesReport({
  workspaceId,
  reportId,
  title,
  token,
}: Props): Promise<API.Report> {
  return axios
    .put(
      `${API_ENDPOINT}/workspaces/${workspaceId}/reports/${reportId}`,
      { title },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((res) => res.data)
    .catch((error) => {
      throw handleError(error);
    });
}
